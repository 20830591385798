import FullPage from 'fullpage.js'
import "fullpage.js/vendors/scrolloverflow";
import 'jquery-form';

$(document).ready(function () {

	function showResponse() {
		$('#formResult').html('спасибо! мы уже отвечаем на ваше письмо!"');
		$('#contactForm').slideUp();
	}

	$('#contactForm').ajaxForm({
		target: '#formResult',
		success: showResponse
	});



	new FullPage('#fullpage', {
		menu: '#menu',
		sectionsColor: ['#ffffff', '#f2f2f2', '#e6edf2', '#dae1e6', '#dae1e6', '#ffffff', '#dae1e6'],
		anchors: ['section', 'section1', 'section2', 'section3', 'section5', 'section6', 'section7'],
		loopTop: false,
		loopBottom: false,
		loopHorizontal: false,
		lazyLoading: true,
		slidesNavigation: true,
		slidesNavPosition: true,
		slideSelector: '.slide',
		licenseKey: 'B666C727-7C714D73-B1910AE2-3C061243',
		scrollOverflow: true,


		afterSlideLoad: function (section, origin, destination, direction) {
			if (destination.isFirst == 1) {
				$('.fp-prev').addClass('disabled')
			} else if (destination.isLast == 1) {
				$('.fp-next').addClass('disabled')
				$('fp-slidesContainer').css({ transform: 'none' })
			}
			else {
				$('.fp-prev').removeClass('disabled');
				$('.fp-next').removeClass('disabled');
			}
		},

		afterLoad: function (origin, destination) {

			/**
			 * selected item menu
			 */
			var urlSection = location.hash;
			$('#menu li').each(function () {
				var link = $(this).find('a').attr('href');

				if (urlSection == link) {
					$(this).addClass('active-item');
				} else {
					$(this).removeClass('active-item')
				}

			});

			function ScrollListener(e) {
				e.stopPropagation()
			}
			$('#priceModal, .feedbackModal')
				.on('show.bs.modal', function () {
					document.querySelector('body')
						.addEventListener('wheel', ScrollListener)
				})
				.on('hide.bs.modal', function () {
					document.querySelector('body')
						.removeEventListener('wheel', ScrollListener)
				});



			/**
			 * modal
			 */
			$('#modal-video').click(function () {
				var src = 'https://www.youtube.com/embed/i9tpyJ3U7EI';
				$('#myModal').modal('show');
				$('#myModal iframe').attr('src', src);
			});

			$('#myModal button').click(function () {
				$('#myModal iframe').removeAttr('src');
			});

			if (destination.index == 0) {
				$('.btn-consultation-position').show();
			}

			if (destination.index == 1) {
				$('.img-sklad').animate({
					top: '-65px'
				})
				$('.img-map').animate({
					top: '-205px'
				})
				$('.text-section2').animate({
					left: '0'
				})
			}

			if (destination.index == 2) {
				$('.item-advantages').animate({
					left: '0',
				})
				$('.btn-price a').animate({
					left: '0',
				})
			}

			if (destination.index == 3) {
				$('.section4-item').animate({
					right: '0',
				})
			}

			// if (destination.index == 4) {
			// 	$('.phone-number').addClass('text-white')
			// 	$('.copyright-design p').addClass('text-white')
			// 	$('.block-animate-text-left ').animate({
			// 		top: '0',
			// 	})
			// 	$('.section5-location').animate({
			// 		right: '0',
			// 	})
			// 	$('.dark-logo').hide();
			// 	$('.white-logo').show();
			// }

			if (destination.index == 4) {
				$('.title-customer-feedback').css({ left: '0' })
			}

			if (destination.index == 5) {
				$('.download-price-btn').show();
			}

			if (destination.index == 6) {
				$('.phone-number').addClass('text-white')
				$('.copyright-design p').addClass('text-white')
				$('.scroll-down').hide()
				$('#menu li').addClass('text-white')
				$('.line-dot').addClass('backg-white')
				$('.dark-logo').hide();
				$('.white-logo').show();
			}

			fullpage_api.setScrollingSpeed(300)
		},

		onLeave: function (origin, destination, direction) {

			if (destination.index != 0) {
				$('.btn-consultation-position').hide();
			}

			if (destination.index != 1) {
				$('.img-sklad').animate({
					top: '260%',
				})
				$('.img-map').animate({
					top: '-205%',
				})
				$('.text-section2').animate({
					left: '180%',
				})
			}

			if (destination.index != 2) {
				$('.item-advantages').animate({
					left: '200%',
				})
				$('.btn-price a').animate({
					left: '-300%',
				})
			}


			if (destination.index != 4) {
				$('.phone-number').removeClass('text-white')
				$('.copyright-design p').removeClass('text-white')
				$('.dark-logo').show();
				$('.white-logo').hide();
			}

			if (destination.index != 3 || destination.index == 6) {

				$('.section4-item').animate({
					right: '-300%',
				})
			}


			if (destination.index != 4 || destination.index == 5) {
				$('.block-animate-text-left ').animate({
					top: '-800px',
				})
				$('.section5-location').animate({
					right: '-200%',
				})
				$('.title-customer-feedback').css({ left: '-200%' })

			}


			if (destination.index != 5) {
				$('.title-customer-feedback').css({ left: '-200%' })
				$('#menu li').removeClass('text-white')
				$('.download-price-btn').hide();
				$('.line-dot').removeClass('backg-white')
				$('.scroll-down').show()
			}


			if (destination.index == 5) {
				$('.phone-number').removeClass('text-white')
				$('.copyright-design p').removeClass('text-white')
				$('.scroll-down').show()
				$('#menu li').removeClass('text-white')
				$('.line-dot').removeClass('backg-white')

			}

		}
	});

})










